import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import ActiveLink from '../../../components/active-link'
import BrandCTA from '../../../components/brand-cta'
import { withPrefix } from "gatsby"
import callout from '../../../images/support_center_callout.png'

const metaTags = {
  description: 'Learn about the SUPPRELIN® LA Support Center.',
  keywords: 'Homepage',
  title: 'Support Center | SUPPRELIN® LA (histrelin acetate)'
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>The SUPPRELIN<sup>&reg;</sup> LA Support Center </h1>
        <p>
          Healthcare plans, insurance coverage, copays, and the process for reimbursement can be complex.
          Endo strives to help patients overcome those complexities so they don't interfere
          with treatment.
        </p>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <h2>The SUPPRELIN<sup>&reg;</sup> LA Support Center can help parents and caregivers coordinate the services needed to initiate patient reimbursement.</h2>
        <ul className='brand-list'>
          <li>Benefits investigation assistance</li>
          <li>Prior authorization information</li>
          <li>Billing and coding information</li>
          <li>Assistance with prescription transfer to a specialty pharmacy</li>
        </ul>
      </Col>
      <Col xs={12} md={6}>
        <img src={callout} alt="Reimbursement Contact Info" title="Reimbursement Contact Info" />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <h2>To investigate your patient’s coverage, complete the&nbsp;
          <ActiveLink to={"/pdfs/MM-05735_SLA_SRE_Form_Digital.pdf"} className="text-purple" ExternalLink>
            Service Request Enrollment Form.
          </ActiveLink>
        </h2>
        <p>After the form is submitted, a Support Center representative will contact your office and the patient’s parents or caregivers to review insurance coverage and product access options.</p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={withPrefix("/hcp/billing-coding")}
          LinkCaption="See suggested billing and coding for SUPPRELIN® LA* - support center and savings"
        >
          See suggested billing and coding for <nobr>SUPPRELIN<sup>&reg;</sup> LA*</nobr>

        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/downloads-office-support"
          LinkCaption="Discover important reimbursement forms for your practice - support center and savings"
        >
          Discover important reimbursement forms for <nobr>your practice</nobr>
        </BrandCTA>
      </Col>
    </Row>
    <Row>
      <Col xs="12">
        <p className='footnote asterik'><strong>Coding is part of the clinical decision. Please use codes that most accurately reflect the procedures performed. Suggestions by Endo do not guarantee reimbursement or take the place of professional coding advice.</strong></p>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
